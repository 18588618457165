<template>
  <v-container id="assignments" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <md-card-content class="custom-card-cont">
              <v-card class="card-cont">
                <v-row class="account-search-bar">
                  <v-col cols="12" class="text-field-cont">
                    <v-select
                      v-model="isVerifiedFilter"
                      :items="isVerifiedFilterOptions"
                      label="Filter Verified"
                      :value="false"
                    ></v-select>
                    <v-btn
                      class="ma-2 mt-3 color_class"
                      dark
                      outlined
                      id="clear-filters"
                      :disabled="loading"
                      @click="clearFilters"
                    >
                      Clear filter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </md-card-content>
          </form>
          <div v-if="loading">
            <v-row justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </div>
          <div v-else-if="assignments.length">
            <v-col
              cols="12"
              v-for="assignment in assignments"
              :key="`${assignment.bom_item ? 'bom' : 'trace'}-${assignment.id}`"
            >
              <div>
                <verify-assignment-item
                  :assignment="assignment"
                  :isSuperAdmin="isSuperAdmin"
                  :toggleRerender="toggleRerender"
                  @toggle-verification="showVerifyPopup"
                />
              </div>
            </v-col>
          </div>
          <v-card v-else class="card-cont">
            No transaction requests available
          </v-card>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <Pagination
            style="margin-right: -30px"
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="assignments.length"
          />
        </v-container>
      </v-col>
    </v-row>
    <popup-window
      :active="dialogVisible"
      title="Verify Transaction"
      :has-close="true"
      :has-back="false"
      noMargin
      @popup-close="closeVerifyPopup"
    >
      <div class="file-upload-wrapper">
        <v-file-input
          v-model="file"
          label="Upload verification file (optional)"
          :accept="acceptedFileTypes"
        ></v-file-input>
      </div>
      <div>
        <ui-button
          class="primary long"
          @click="verifyAssignment"
          :disabled="loading"
        >
          <template v-if="!loading">Verify</template>
          <template v-else>
            <v-progress-circular
              indeterminate
              color="white"
            ></v-progress-circular>
          </template>
        </ui-button>
      </div>
    </popup-window>
  </v-container>
</template>

<script>
import auth from "../../auth";
import PopupWindow from "../../components/base/PopupWindow.vue";
import UiButton from "../../components/base/UiButton.vue";
/* eslint-disable @typescript-eslint/camelcase */
import VerifyAssignmentItem from "../../components/Inquiry/VerifyAssignmentItem.vue";
import Pagination from "../../components/Pagination";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import utils from "../../utils";

export default {
  name: "VerifyAssignments",
  components: {
    Pagination,
    VerifyAssignmentItem,
    PopupWindow,
    UiButton,
  },
  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      dialogVisible: false,
      selectedAssignment: null,
      selected: [],
      search: "",
      loading: false,
      assignments: [],
      assignmentType: "bom",
      maxFileSize: 10 * 1024 * 1024,
      file: null,
      selectedHeader: "",
      isVerifiedFilter: null,
      isVerifiedFilterOptions: [
        { text: "Verified", value: true },
        { text: "Unverified", value: false },
      ],
      toggleRerender: false,
      acceptedFileTypes: utils.acceptedFileTypes,
    };
  },
  computed: {
    isSuperAdmin() {
      return !auth.user.account;
    },
  },
  methods: {
    async fetchAssignments() {
      this.loading = true;

      let query = `?page=${this.currentPage}`;

      if (this.isVerifiedFilter !== null) {
        query += `&is_verified=${this.isVerifiedFilter}`;
      }

      try {
        const response = await restAdapter.get(
          `/api/${
            this.isSuperAdmin
              ? "verification_requested_assignments"
              : "assignments"
          }` + query
        );

        if (response.data) {
          this.assignments = response.data.data;
          this.totalCount = response.data.total;
        } else {
          this.selected = [];
          this.assignments = [];
          this.totalCount = 0;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    showVerifyPopup(assignment) {
      this.selectedAssignment = assignment;
      if (assignment.verified) this.verifyAssignment();
      else this.dialogVisible = true;
    },
    closeVerifyPopup() {
      this.assignments = this.assignments.map((assignment) => {
        if (assignment.id === this.selectedAssignment.id) {
          return this.isSuperAdmin
            ? {
                ...assignment,
                verified_by_factory: this.selectedAssignment.verified,
                verified_by_brand: this.selectedAssignment.verified,
              }
            : {
                ...assignment,
                ...(!this.selectedAssignment.otherParty
                  ? {
                      verified_by_factory: this.selectedAssignment.verified,
                      verified_by_brand: this.selectedAssignment.verified,
                    }
                  : this.selectedAssignment.otherParty.type.toLowerCase() ==
                    "brand"
                  ? { verified_by_factory: this.selectedAssignment.verified }
                  : { verified_by_brand: this.selectedAssignment.verified }),
              };
        }

        return assignment;
      });
      this.toggleRerender = !this.toggleRerender;
      this.selectedAssignment = null;
      this.file = null;
      this.dialogVisible = false;
    },
    async verifyAssignment() {
      if (!this.selectedAssignment) return;

      if (this.file && this.file.size > this.maxFileSize) {
        notification.error("File size exceeds the maximum limit (10MB).");
        this.loading = false;
        return;
      }

      const assignmentId = this.selectedAssignment.id;
      const payload = {
        is_verified: !this.selectedAssignment.verified,
        type:
          this.selectedAssignment.assignmentType.toLowerCase() === "bom"
            ? "bom"
            : "trace",
        ...(this.file ? { file: this.file } : {}),
      };

      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        formData.append(key, payload[key]);
      });

      try {
        await restAdapter
          .post(
            `/api/assignments/${assignmentId}/${
              this.isSuperAdmin
                ? "force_change_verification_status"
                : "change_verification_status"
            }`,
            formData
          )
          .then(() => {
            this.selectedAssignment = {
              ...this.selectedAssignment,
              verified: !this.selectedAssignment.verified,
            };
            this.closeVerifyPopup();
            notification.success(
              "Transaction verification status updated successfully"
            );
          });
      } catch (error) {
        if (
          error.response &&
          error.response.status == 400 &&
          error.response.data &&
          error.response.data.error
        ) {
          this.closeVerifyPopup();
          notification.error(
            "An error occurred while verifying the transaction"
          );
        } else {
          notification.error("Invalid file type");
        }
      } finally {
        this.loading = false;
      }
    },
    onFiltersChange() {
      this.currentPage = 1;
      this.fetchAssignments();
    },
    loadPage(page) {
      this.currentPage = page;
      this.fetchAssignments();
    },
    handleAssignmentClick(assignment) {
      this.selectedAssignment = assignment;
      this.assignmentType = assignment.bom_item
        ? "bom"
        : assignment.trace
        ? "trace"
        : "bom";
      this.dialogVisible = true;
    },
    clearFilters() {
      this.isVerifiedFilter = null;
    },
  },
  watch: {
    isVerifiedFilter: {
      handler: function (value) {
        this.onFiltersChange();
      },
      immediate: true,
    },
  },
};
</script>

<style>
.text-element-5 {
  text-align: right;
  padding-right: 25px;
}
.verification-btn {
  width: 100px;
}
.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}
.verification-success {
  background-color: #64d5ca;
}
.verification-error {
  background-color: #f66d9b;
}
.style-paginate {
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}
.assignment-align {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.popup-card {
  padding: 20px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.centered-content {
  margin: 0 auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  flex: 1;
  font-weight: bold;
  text-align: left;
}

.separator {
  margin: 0 10px;
  text-align: center;
}

.data {
  flex: 2;
  text-align: left;
}

.center-aligned {
  text-align: center;
  margin-top: 20px;
}
.assignment-search-bar {
  background: #ffffff;
  border-radius: 7px;
}
.btn-cont {
  text-align: right;
}
.card-cont {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.header-container {
  color: #442d65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}
.assignment-container {
  align-items: center;
  color: #442d65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #ffffff;
  text-align: left;
}
.custom-text-field ::v-deep label {
  color: #442d65;
}
.custom-card-cont {
  margin: 0px -15px;
}
.text-element-btn {
  justify-content: center;
  display: flex;
  align-items: center;
}
.text-element-1 {
  padding-left: 0px;
}
.text-element-2 {
  padding-left: 4px;
}
.theme--light.v-input {
  margin-right: 20px;
}
.theme--light.v-label {
  color: #442d65 !important;
}
.mdi-magnify::before {
  color: #442d65 !important;
}
.mdi-magnify::after {
  color: #442d65 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442d65 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442d65;
  border-color: #442d65;
  border-style: solid;
  border-width: thin 0 thin 0;
}

.file-upload-wrapper {
  margin-left: calc(8% - 9px);
}
</style>
